<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>居民健康值管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" style="width: 70% !important;">
        <div class="input_box">
          <span style="width: 80px;">姓名：</span>
          <el-input placeholder="请输入" v-model="searchForm.userName"></el-input>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>地区：</span>
          <el-select v-model="searchForm.code" filterable @focus="getArea" @change="getAreaCode" clearable
            placeholder="仅用作筛选社区">
            <el-option v-for="item in araeList" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>社区：</span>
          <el-select v-model="searchForm.comId" clearable placeholder="请选择" @change="getGroupList">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="input_box" v-if="this.$store.state.role === 'admin'">
          <span>小组：</span>
          <el-select v-model="searchForm.gropId" clearable placeholder="请选择">
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>积分类型：</span>
          <el-select v-model="searchForm.type" clearable placeholder="请选择">
            <el-option v-for="item in taskTypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span>积分值：</span>
          <el-input placeholder="起" v-model="searchForm.startValue" style="width: 80px;"></el-input>
          <span>-</span>
          <el-input placeholder="止" v-model="searchForm.endValue" style="width: 80px;"></el-input>
        </div>
        <div class="input_box">
          <span>积分时间：</span>
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="searchForm.startDate" type="datetime"
            placeholder="日期起" style="width:190px" :picker-options="beginPickerOptions" @focus="focus"></el-date-picker>
          <span>-</span>
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="searchForm.endDate" type="datetime"
            placeholder="日期止" style="width:190px" :picker-options="endPickerOptions" @focus="focus"></el-date-picker>
        </div>
      </div>
      <div class="operate_btn" style="width:30% !important">
        <el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addScore">新增</el-button>
        <el-button type="warning" icon="el-icon-download" @click="uploadExcel">导出</el-button>
      </div>
    </div>
    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <!--<el-table-column-->
        <!--type="selection"-->
        <!--width="45">-->
        <!--</el-table-column>-->
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="userName" width="160" label="姓名" />
        <el-table-column prop="comName" label="社区" />
        <el-table-column prop="gropName" label="小组" />
        <el-table-column prop="testScore" label="积分类型" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">膳食打卡</span>
            <span v-if="scope.row.type == 1">运动打卡</span>
            <span v-if="scope.row.type == 2">血压监测打卡</span>
            <span v-if="scope.row.type == 3">用药打卡</span>
            <span v-if="scope.row.type == 4">观看直播</span>
            <span v-if="scope.row.type == 5">考试打卡</span>
            <span v-if="scope.row.type == 6">分享打卡</span>
            <span v-if="scope.row.type == 7">监测打卡</span>
            <span v-if="scope.row.type == 8">综合打卡</span>
            <span v-if="scope.row.type == 9">血脂监测打卡</span>
            <span v-if="scope.row.type == 10">血糖监测打卡</span>
            <span v-if="scope.row.type == 11">体重监测打卡</span>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="积分值" width="100" />
        <el-table-column prop="creatTime" label="积分时间" width="160" />
        <el-table-column prop="rate" label="积分来源" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.source == 1">打卡</span>
            <span v-if="scope.row.source == 2">健康指标打卡</span>
            <span v-if="scope.row.source == 3">后台添加</span>
            <span v-if="scope.row.source == 4">直播打卡</span>
          </template>
        </el-table-column>
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click="delScore(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!--查看详情-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>
        {{ operateBox.title }}
      </div>
      <edit-from :key="timer" @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from './components/edit-from'
export default {
  name: 'index',
  components: {
    EditFrom
  },
  data() {
    return {
      beginPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.endDate) {
            const endTime = new Date(this.searchForm.endDate)
            return time.getTime() >= endTime.getTime()
          } else {
            return false
          }
        }
      },
      endPickerOptions: {
        disabledDate: (time) => {
          if (this.searchForm.startDate) {
            const beginTime = new Date(this.searchForm.startDate)
            return time.getTime() <= beginTime.getTime()
          } else {
            return false
          }
        }
      },
      communityList: [],
      groupList: [],
      tableData: [],
      taskTypeList: [
        { value: 0, label: '膳食打卡' },
        { value: 1, label: '运动打卡' },
        { value: 2, label: '血压监测打卡' },
        { value: 3, label: '用药打卡' },
        { value: 4, label: '直播打卡' },
        { value: 5, label: '考试打卡' },
        { value: 6, label: '分享打卡' },
        { value: 7, label: '监测打卡' },
        { value: 8, label: '综合打卡' }
      ],
      editDialog: false,
      operateBox: {
        title: '新增积分',
        operate: 'add',
        id: null
      },
      searchForm: {
        trueName: '',
        communityId: null,
        communityIds: null,
        gropId: null,
        type: null,
        startValue: '',
        endValue: '',
        startDate: '',
        endDate: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      timer: null,
      analysisDialog: false,
      araeList: []
    }
  },
  created() {
    this.fetchUserPower()
    // this.getCommityList()
  },
  methods: {
    async getAreaCode(val) {
      this.searchForm.communityId = null
      let data = {
        code: val,
      }
      let pageBox = {
        pageNum: 1,
        pageSize: 99999
      }
      let res = await api.getCommunitySystemUser(data, pageBox)
      if (res.code == 200 && res.rows) {
        this.communityList = res.rows
      } else {
        this.communityList = []
      }
    },
    getArea() {
      this.araeList = this.$store.state.userArea
    },
    // 根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        const res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getList()
        }
      } catch (e) { }
    },
    // 获取社区列表
    async getCommityList() {
      const searchForm = {
        code: ''
      }
      const pageBox = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        try {
          const res = await api.getCommunitySystemUser(searchForm, pageBox)
          if (res.code == 200) {
            this.communityList = res.rows
          }
        } catch (e) {
          this.$message.error(e.msg)
        }
      } catch (e) { }
    },
    async getGroupList(val) {
      this.searchForm.groupId = ''
      try {
        const res = await api.getGroupListByCompId(val)
        if (res.code == 200) {
          this.groupList = res.data
        }
      } catch (e) { }
    },
    async getList() {
      try {
        const res = await api.getScoreDetails(this.searchForm, this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) { }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getList()
    },
    // 条件查询
    searchBtn() {
      if (this.searchForm.startValue !== '' && this.searchForm.endValue !== '') {
        if (Number(this.searchForm.startValue) > Number(this.searchForm.endValue)) {
          return this.$message.warning('积分值:止必须大于起')
        }
      }
      this.getList()
    },
    // 删出积分记录
    delScore(row) {
      this.$confirm('删除用户会影响小组积分和排名，确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delUserScore(row.id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async delUserScore(ids) {
      try {
        const res = await api.delScore(ids)
        if (res.code == 200) {
          this.$message.success('删除成功！')
          this.getList()
        }
      } catch (e) { }
    },
    // 新增积分
    addScore() {
      this.timer = new Date().getTime()
      this.editDialog = true
    },
    // 导出excel
    async uploadExcel() {
      try {
        const res = await api.userScoreExport(this.searchForm)
        const name = '积分明细'
        const blob = new Blob([res], { type: 'xls' })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = `${name}.xls` // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
        this.$message.success('导出成功')
      } catch (e) { }
    },
    closed() {
      this.getList()
      this.editDialog = false
    },
    // 隐藏此刻按钮
    focus() {
      this.$nextTick(() => {
        document
          .getElementsByClassName('el-button--text')[0]
          .setAttribute('style', 'display:none')
      })
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.set_input_style {
  width: 180px;
}

.search_box {
  width: 65% !important;
  flex-wrap: wrap;
  height: 90px;
}

.operate_btn {
  width: 40% !important;
  text-align: right;
}

.table_box {
  height: calc(100% - 193px);
  margin: 10px;
}

.statis_table {
  height: calc(100% - 153px);
}

.picker_box {
  width: 180px !important;
}

/deep/.el-select__tags {
  flex-wrap: unset;
  overflow: auto;
}

.statis {
  margin: 0 !important;
  height: 100%;
  width: 100%;
}
</style>
