const diseaseData = [
  {
    value: 1,
    label: '多囊卵巢综合症'
  },
  {
    value: 3,
    label: '颈椎病'
  },
  {
    value: 4,
    label: '子宫肌瘤'
  },
  {
    value: 7,
    label: '痛风'
  },
  {
    value: 8,
    label: '高血脂症'
  },
  {
    value: 9,
    label: 'Ⅱ型糖尿病'
  },
  {
    value: 10,
    label: '高血压'
  },
  {
    value: 11,
    label: '超重或肥胖'
  }
]
const healthConditionData = [
  {
    value: 8,
    label: '慢性病'
  },
  {
    value: 0,
    label: '其它'
  },
  {
    value: 9,
    label: '健康'
  }
]
const taskTypeList = [
  { value: 0, label: "膳食打卡" },
  { value: 1, label: "运动打卡" },
  { value: 2, label: "血压监测打卡" },
  { value: 3, label: "用药打卡" },
  //{value:4,label:"直播打卡"},
  // {value:5,label:"考试打卡"},
  // {value:6,label:"分享打卡"},
  { value: 7, label: "监测打卡" },
  { value: 8, label: "综合打卡" }
]
const submitTypeList = [
  { value: 0, label: "文字+图片打卡" },
  { value: 1, label: "选择打卡(文字)" },
  // { value: 2, label: "视频打卡" },
  { value: 3, label: "问答题打卡" },
  { value: 4, label: "选择打卡(图片)" },
]
const taskUnit = [
  { value: 7, label: '周' },
  { value: 15, label: '半月' },
  { value: 30, label: '月' }
]
const timeSlot = [{
  value: 1,
  label: '实时排名'
}, {
  value: 2,
  label: '本周排名'
}, {
  value: 3,
  label: '上周排名'
}, {
  value: 4,
  label: '自定义'
}]

const testQtype = [
  { value: 1, label: '单选题' },
  { value: 2, label: '多选题' },
  // {value:3,label:'填空题'},
  { value: 3, label: '问答题' }
]
const paperType = [
  { value: 1, label: '考题形式' },
  { value: 2, label: '问卷形式' }
]

const statusList = [
  { value: 1, label: "已发布" },
  { value: 2, label: "已停用" }
]
export { diseaseData, healthConditionData, taskTypeList, submitTypeList, taskUnit, timeSlot, testQtype, paperType, statusList }
