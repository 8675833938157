<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="已选居民:" prop="userId">
        <el-select v-model="ruleForm.userId" filterable clearable>
          <el-option v-for="item in personList" :label="item.trueName" :value="item.id">{{ item.trueName }}
            <span style="color: #666;font-size: 12px" v-if="item.communityName && item.groupName">
              {{ item.communityName }}/{{ item.groupName }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-form-item label="积分类型:" prop="type">
          <el-select v-model="ruleForm.type" clearable>
            <el-option v-for="item in taskTypes" :label="item.label" :value="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="分数:" prop="score">
        <el-input v-model="ruleForm.score"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { chinaArea } from '@/utils/china-area-data'
import { taskTypeList } from '@/utils/baseData'
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      ruleForm: {
        userId: '',
        type: '',
        score: '',
        livingId: ''
      },
      taskTypes: taskTypeList,
      personList: [],
      rules: {
        userId: [
          { required: true, message: '用户不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '积分类型不能为空', trigger: 'blur' },
        ],
        score: [
          { required: true, message: '积分不能为空', trigger: 'blur' },
        ]
      }
    };
  },
  created() {
    this.getResidents()
  },
  methods: {

    //获取居民列表
    async getResidents() {
      let data = {}
      let page = {
        pageSize: 999999,
        pageNum: 1,
      }
      try {
        let res = await api.getResidentList(data, page)
        if (res.code == 200) {
          this.personList = res.rows
        }
      } catch (e) {
        debugger
        this.$message.error(e.msg)
      }
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm
          this.scoreAdd(data)
        } else {
          return false;
        }
      });
    }, 500),
    //新增积分
    async scoreAdd(p) {
      try {
        let res = await api.addScore(p)
        if (res.code == 200) {
          this.$message.success('新增成功！')
          this.$emit('closed')
        } else {
          this.$message.warning(res.mag)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    cancelFrom(formName) {
      this.$emit('closed')
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%
}
</style>
